<template>
    <div>
        <RouterView
            class="fill-to-footer"
        />
        <div class="navigation">
            <RouterLink :to="{name: 'christmas-cards.2023'}">
                Christmas Card 2023
            </RouterLink>
            <RouterLink :to="{name: 'christmas-cards.2022'}">
                Christmas Card 2022
            </RouterLink>
            <RouterLink :to="{name: 'christmas-cards.2021'}">
                Christmas Card 2021
            </RouterLink>
            <RouterLink :to="{name: 'christmas-cards.2020'}">
                Christmas Card 2020
            </RouterLink>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AllCards',
    };
</script>
<style lang="sass">
    html, body, .website-container
        background: rgb(255, 128, 129)
        background: radial-gradient(circle, rgba(255, 128, 129, 1) 0%, rgba(212, 36, 38, 1) 100%)

        .fill-to-footer
            min-height: calc(100vh - 5.5rem)

        .navigation
            position: relative
            z-index: 5
            width: calc(100vw - 4rem)
            background-image: url('../../assets/fabric_texture.png')
            background-repeat: repeat
            padding: 2rem
            display: flex
            flex-direction: row
            align-items: flex-start
            height: 1.5rem

            a
                font-size: 1rem
                color: $red
                margin-right: 2rem

</style>
